@font-face {
	font-family: 'icomoon';
	src:  url('./../fonts/icomoon/icomoon.eot?r8tqa0');
	src:  url('./../fonts/icomoon/icomoon.eot?r8tqa0#iefix') format('embedded-opentype'),
		url('./../fonts/icomoon/icomoon.ttf?r8tqa0') format('truetype'),
		url('./../fonts/icomoon/icomoon.woff?r8tqa0') format('woff'),
		url('./../fonts/icomoon/icomoon.svg?r8tqa0#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-envelope-solid:before {
	content: "\e900";
}
.icon-envelope-bold:before {
	content: "\e901";
}
.icon-brightness-bold:before {
	content: "\e902";
}
.icon-brightness-regular:before {
	content: "\e903";
}
.icon-brightness-solid:before {
	content: "\e904";
}
.icon-moon-stars-regular:before {
	content: "\e905";
}
.icon-moon-stars-bold:before {
	content: "\e906";
}
.icon-moon-stars-solid:before {
	content: "\e907";
}
.icon-qr-code-regular:before {
	content: "\e908";
}
.icon-qr-code-solid:before {
	content: "\e909";
}
.icon-search-regular:before {
	content: "\e90a";
}
.icon-search-solid:before {
	content: "\e90b";
}
.icon-phone-regular:before {
	content: "\e90c";
}
.icon-gem-regular:before {
	content: "\e90d";
}
.icon-bulb-regular:before {
	content: "\e90e";
}
.icon-bulb-solid:before {
	content: "\e90f";
}
.icon-gem-solid:before {
	content: "\e910";
}
.icon-dribbble:before {
	content: "\e911";
}
.icon-facebook:before {
	content: "\e912";
}
.icon-fiverr:before {
	content: "\e913";
}
.icon-flickr:before {
	content: "\e914";
}
.icon-foursquare:before {
	content: "\e915";
}
.icon-freelancer:before {
	content: "\e916";
}
.icon-github:before {
	content: "\e917";
}
.icon-globe-regular:before {
	content: "\e918";
}
.icon-instagram:before {
	content: "\e919";
}
.icon-linkedin:before {
	content: "\e91a";
}
.icon-odnoklassniki:before {
	content: "\e91b";
}
.icon-pinterest:before {
	content: "\e91c";
}
.icon-reddit:before {
	content: "\e91d";
}
.icon-soundcloud:before {
	content: "\e91e";
}
.icon-spotify:before {
	content: "\e91f";
}
.icon-tiktok:before {
	content: "\e920";
}
.icon-tumblr:before {
	content: "\e921";
}
.icon-twitter:before {
	content: "\e922";
}
.icon-upwork:before {
	content: "\e923";
}
.icon-vimeo:before {
	content: "\e924";
}
.icon-youtube:before {
	content: "\e925";
}
.icon-behance:before {
	content: "\e926";
}
.icon-blogger:before {
	content: "\e927";
}
.icon-settings-regular:before {
	content: "\e928";
}
.icon-settings-sliders-regular:before {
	content: "\e929";
}
.icon-settings-sliders-solid:before {
	content: "\e92a";
}
.icon-settings-solid:before {
	content: "\e92b";
}
.icon-share-regular:before {
	content: "\e92c";
}
.icon-share-solid:before {
	content: "\e92d";
}
.icon-sign-out-regular:before {
	content: "\e92e";
}
.icon-ticket-regular:before {
	content: "\e92f";
}
.icon-token-regular:before {
	content: "\e930";
}
.icon-user-regular:before {
	content: "\e931";
}
.icon-user-solid:before {
	content: "\e932";
}
.icon-align-left-bold:before {
	content: "\e933";
}
.icon-align-left-regular:before {
	content: "\e934";
}
.icon-angle-left-regular:before {
	content: "\e935";
}
.icon-apps-regular:before {
	content: "\e936";
}
.icon-apps-solid:before {
	content: "\e937";
}
.icon-arrow-up-bold:before {
	content: "\e938";
}
.icon-arrow-up-regular:before {
	content: "\e939";
}
.icon-badge-regular:before {
	content: "\e93a";
}
.icon-badge-solid:before {
	content: "\e93b";
}
.icon-bookmark-regular:before {
	content: "\e93c";
}
.icon-bookmark-solid:before {
	content: "\e93d";
}
.icon-chart-network-regular:before {
	content: "\e93e";
}
.icon-chart-pie-regular:before {
	content: "\e93f";
}
.icon-chart-pie-solid:before {
	content: "\e940";
}
.icon-check-regular:before {
	content: "\e941";
}
.icon-chevron-double-up-bold:before {
	content: "\e942";
}
.icon-chevron-double-up-regular:before {
	content: "\e943";
}
.icon-comments-regular:before {
	content: "\e944";
}
.icon-comments-solid:before {
	content: "\e945";
}
.icon-credit-card-regular:before {
	content: "\e946";
}
.icon-cross-circle-regular:before {
	content: "\e947";
}
.icon-dashboard-regular:before {
	content: "\e948";
}
.icon-dashboard-solid:before {
	content: "\e949";
}
.icon-double-sign-out-regular:before {
	content: "\e94a";
}
.icon-envelope-regular:before {
	content: "\e94b";
}
.icon-eye-crossed-regular:before {
	content: "\e94c";
}
.icon-eye-regular:before {
	content: "\e94d";
}
.icon-feed-regular:before {
	content: "\e94e";
}
.icon-feed-solid:before {
	content: "\e94f";
}
.icon-filter-regular:before {
	content: "\e950";
}
.icon-filter-solid:before {
	content: "\e951";
}
.icon-heart-regular:before {
	content: "\e952";
}
.icon-heart-solid:before {
	content: "\e953";
}
.icon-info-regular:before {
	content: "\e954";
}
.icon-interrogation-regular:before {
	content: "\e955";
}
.icon-key-regular:before {
	content: "\e956";
}
.icon-life-ring-regular:before {
	content: "\e957";
}
.icon-list-regular:before {
	content: "\e958";
}
.icon-magic-wand-regular:before {
	content: "\e959";
}
.icon-magic-wand-solid:before {
	content: "\e95a";
}
.icon-marker-regular:before {
	content: "\e95b";
}
.icon-megaphone-regular:before {
	content: "\e95c";
}
.icon-megaphone-solid:before {
	content: "\e95d";
}
.icon-picture-regular:before {
	content: "\e95e";
}
.icon-playing-cards-regular:before {
	content: "\e95f";
}
.icon-playing-cards-solid:before {
	content: "\e960";
}
.icon-plus-regular:before {
	content: "\e961";
}
.icon-portrait-regular:before {
	content: "\e962";
}
.icon-rocket-bold:before {
	content: "\e963";
}
.icon-rocket-regular:before {
	content: "\e964";
}
.icon-rocket-solid:before {
	content: "\e965";
}
.icon-ticket-solid:before {
	content: "\e966";
}
.icon-dashboard-bold:before {
	content: "\e967";
}
