@import './assets/styles/settings';

.zero-scrolled {
	.SIDE__progress-item:nth-child(1) {
		transform: scale(2);
	}
}

.onethird-scrolled {
	.SIDE__progress-item:nth-child(1) {
		transform: scale(2);
	}

	.SIDE__progress-item:nth-child(2) {
		transform: scale(2);
	}
}

.twothirds-scrolled {
	.SIDE__progress-item:nth-child(1) {
		transform: scale(2);
	}

	.SIDE__progress-item:nth-child(2) {
		transform: scale(2);
	}

	.SIDE__progress-item:nth-child(3) {
		transform: scale(2);
	}
}

.MAINLAYOUT {
	// padding: 80px 0 0 0;
	
	// @media (max-width: $sm - 1) {
	// 	padding: 60px 0 0 0;
	// }
}

.SIDE {
	position: fixed;
	width: 100px;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	pointer-events: none;

	@media (max-width: $md + 150) {
		display: none;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--bg2);
		opacity: 0.2;
	}

	&__progress {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: var(--bg2);
		padding: 0 $space-xs $space-xs;
		border-radius: $radius-md;
		box-shadow: $shadow1;

		&-item {
			width: $space-xxs;
			height: $space-xxs;
			border-radius: $radius-xl;
			background: var(--border2);
			margin: $space-xs 0 0;
			transition: transform $duration-sm;
		}
	}
}
