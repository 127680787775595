@import './../../assets/styles/settings';
@import './../../assets/styles/animations';

.customAnimation.animationTriggered {
	transform: scale(1);
	
	.PROJECTITEM {
		&__preview {
			&-link {
				&:after {
					opacity: 0;
				}
			}

			&-cover {
				&:before,
				&:after {
					width: 0;
				}
			}
		}
	}
}

.PROJECTITEM {
	position: relative;

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}

	&__preview {
		background-color: var(--bg2);
		box-shadow: $shadow2;
		position: relative;
		transform: scale(0.8);
		transition: transform $duration-xxl;
		border-radius: $radius-md;
		overflow: hidden;
		@include aspect-ratio(10, 8);

		&:hover {
			.PROJECTITEM__preview-link {
				&:before {
					opacity: 0.2;
				}
			}
		}

		&-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: var(--bg2);
				transition: opacity $duration-xl;
				z-index: 3;
				opacity: 0;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: var(--bg2);
				transition: opacity $duration-xxl 1s;
				z-index: 2;
				opacity: 0.4;
			}
		}

		&-image {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&-cover {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: var(--main-clr2);
				transition: width $duration-lg 600ms;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: var(--bg2);
				transition: width $duration-lg 500ms;
			}
		}

		&-status {
			position: absolute;
			top: $space-sm;
			right: 0;
			background: green;
			display: inline-block;
			color: $light;
			font-size: $font-xxs;
			line-height: $lh-xxs;
			vertical-align: top;
			padding: 0 $space-xs;
			border-top-left-radius: $radius-md;
			border-bottom-left-radius: $radius-md;

			&.completed {
				background: $green;
			}

			&.in-progress {
				background: $yellow;
			}

			&.queued {
				background: $red;
			}
		}
	}

	&__body {
		padding: $space-sm $space-sm $space-md;

		@media (max-width: $sm - 1) {
			padding: $space-xs $space-xs $space-sm;
			text-align: center;
		}

		.SECTION__title {
			color: var(--text1);
			font-weight: 500;
		}

		.SECTION__subtitle {
			color: var(--main-clr2);
		}
	}
}