@import './../../assets/styles/settings';

.RESOLUTION {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background: var(--main-clr1);
	text-align: center;

	@media (max-width: 319px) {
		display: block;
	}

	&__inner {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 100%;

		&-text {
		}
	}
}
