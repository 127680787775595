@import './../../assets/styles/settings';
@import './../../assets/styles/settings';

.zero-scrolled,
.onethird-scrolled,
.twothirds-scrolled {
	.ABOUT {
		&__scene {
			&-item {
				&-image {
					transform: scale(0.98);
					opacity: 0;
				}
			}
		}

		&__intro {
			&-scroll {
				opacity: 0;
				top: ($space-xxs - 100px);
			}
		}
	}
}

.ABOUT {
	&__scene {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0%;
		left: 0%;
		pointer-events: none;

		&.diff1 {
			width: 120%;
			height: 120%;
			top: -10%;
			left: -10%;
		}

		&-item {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			&-image {
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				position: relative;
				right: 0;
				top: 0;
			}
		}
	}

	&__intro {
		position: relative;
		min-height: 600px;

		&-image {
			@include aspect-ratio(1, 1);
			max-width: 300px;
			max-height: 300px;
			margin: 0 auto;
			z-index: -2;

			@media (max-width: $sm - 1) {
				max-width: 200px;
				max-height: 200px;
			}

			svg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		&-bottom {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 0;
			padding: 0 0 $space-sm;

			@media (max-width: $sm - 1) {
				padding: 0 0 $space-xs;
			}
		}

		&-links {
			margin: 0 (0 - $space-xxs);

			&-item {
				color: var(--headings1);
				display: inline-block;
				vertical-align: top;
				width: 30px;
				height: 30px;
				margin: 0 $space-xxs;
				border: 1px solid var(--headings1);
				border-radius: $radius-xs;
				padding: ($space-xs - 1);
				transform: scale(1);
				transition: transform $duration-xs, border $duration-xs;

				&:hover {
					transform: scale(1.1);
				}

				&:active {
					border: 1px solid var(--main-clr2);

					[class*="icon-"] {
						color: var(--main-clr2);
					}
				}

				[class*="icon-"] {
					font-size: 10px;
					vertical-align: top;
					color: var(--headings1);
					transition: color $duration-xs;
				}
			}
		}

		&-items {
			display: flex;
			justify-content: space-between;
		}

		&-scroll {
			position: absolute;
			top: $space-xxs;
			left: calc(50% - 50px);
			padding-left: 50px;
			transform: rotate(-90deg);
			color: var(--border2);
			opacity: 1;
			transition: opacity $duration-lg, top $duration-lg;

			@media (max-width: $sm - 1) {
				top: -5px;
			}

			&:before {
				content: "";
				width: 40px;
				height: 2px;
				position: absolute;
				background: var(--border2);
				top: 50%;
				left: 0;
			}
		}
	}

	&__statitem {
		.SECTION__title {
			color: var(--text1);
		}

		.SECTION__subtitle {
			color: var(--main-clr2);
		}
	}
}