@import './../../assets/styles/settings';
@import './../../assets/styles/grid';

.FOOTER {
	background: var(--bg-footer);
	text-align: center;
	
	&__logo {
		margin: $space-sm 0 0;
		display: inline-block;

		@media (max-width: $sm - 1) {
			margin: $space-xs 0 0;
		}

		&-link {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			opacity: 0.2;

			.logo {
				display: inline-block;
				vertical-align: top;
				width: auto;
				height: 30px;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
			}

			.text {
				font-size: $font-xl;
				letter-spacing: -0.99px;
				font-weight: bold;
				margin: 0 0 0 $space-xxs;
				color: var(--headings1);

				span {
					display: none;
				}
			}

			.badge {
				position: absolute;
				top: 0;
				left: 100%;
				color: var(--placeholders1);
				font-size: $font-xxxs;
				font-weight: bold;
				margin: 0 0 0 $space-xxs;

				@media (max-width: $sm - 1) {
					display: none;
				}
			}
		}
	}

	&__item {
		line-height: $lh-sm;
		margin: $space-sm 0 0;

		@media (max-width: $sm - 1) {
			margin: $space-xs 0 0;
		}

		&-links {
			margin: 0 (0 - $space-xs);
			
			a {
				margin: 0 $space-xs;
				color: var(--links1);
			}
		}

		p {
			color: var(--text1);
		}
	}
}