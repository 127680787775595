@import './../../assets/styles/settings';

.zero-scrolled,
.onethird-scrolled,
.twothirds-scrolled {
	.HEADER {
		&__wrap {
			background: var(--bg-nav);
			box-shadow: $shadow1;
			border-bottom: 1px solid transparent;
		}
	}
}

.HEADER {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 5;

	&.active {
		.HEADER {
			&__wrap {
				background: var(--bg-nav);
				box-shadow: none;
				border-bottom: 1px solid var(--border1);
			}

			&__toggler-item {
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					top: calc(50% - 1px);
					width: 100%;
				}

				&:nth-child(1),
				&:nth-child(2) {
					transform: rotate(-45deg);
				}

				&:nth-child(3),
				&:nth-child(4) {
					transform: rotate(45deg);
				}
			}

			&__overflow {
				transform: translateY(0%);
				transition: transform $duration-lg 0s cubic-bezier(0.5, 0, 0, 1);
			}

			&__navigation {
				transform: translateY(0%);
				transition: transform $duration-lg $duration-xs cubic-bezier(0.5, 0, 0, 1);
			}
		}
	}

	&__wrap {
		position: relative;
		z-index: 3;
		background: transparent;
		border: 1px solid transparent;
		transition: border $duration-sm, background-color $duration-sm;
	}

	&__inner {
		position: relative;
		z-index: 3;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		height: 80px;

		@media (max-width: $sm - 1) {
			height: 60px;
		}
	}

	&__logo {
		&-link {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;

			.logo {
				display: inline-block;
				vertical-align: top;
				width: auto;
				height: 30px;
			}

			.text {
				font-size: $font-xl;
				letter-spacing: -0.99px;
				font-weight: bold;
				margin: 0 0 0 $space-xxs;
				color: var(--headings1);

				@media (max-width: $sm - 1) {
					display: none;
				}

				span {
					display: none;
				}
			}

			.badge {
				position: absolute;
				top: 0;
				left: 100%;
				color: var(--main-clr1);
				font-size: $font-xxxs;
				font-weight: bold;
				margin: 0 0 0 $space-xxs;

				@media (max-width: $sm - 1) {
					display: none;
				}
			}
		}
	}

	&__content {
    	text-align: right;
		display: flex;
		margin: 0 (0 - $space-xxs);
		align-items: center;

		&-item {
			text-align: center;
			user-select: none;
			position: relative;
			width: 100%;
			margin: 0 $space-xxs;
		}
	}

	&__toggler {
		cursor: pointer;
		width: 30px;
		height: 20px;
		// background: red;

		@media (max-width: $sm - 1) {
			padding: $space-xs;
		}

		&:hover {
			.HEADER__toggler-item {
				width: 100%;
			}
		}

		&:active {
			.HEADER__toggler-item {
				background-color: var(--main-clr2);
			}
		}

		&-item {
			width: 100%;
			height: 2px;
			position: absolute;
			right: 0;
			background-color: var(--headings1);
			transform: rotate(0);

			&:nth-child(1) {
				top: 0;
				width: 90%;
				transition: all $duration-sm;
			}

			&:nth-child(2) {
				top: calc(25% + 1px);
				width: 100%;
				transition: all $duration-sm 20ms;
			}

			&:nth-child(3) {
				top: calc(75% - 3px);
				width: 60%;
				transition: all $duration-sm 40ms;
			}

			&:nth-child(4) {
				top: calc(100% - 2px);
				width: 90%;
				transition: all $duration-sm 60ms;
			}
		}
	}

	&__overflow {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translateY(-100%);
		transition: transform $duration-lg $duration-xs cubic-bezier(0.5, 0, 0, 1);
		background: var(--bg-nav);
		opacity: 0.9;
		z-index: 1;
		pointer-events: none;
	}

	&__navigation {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translateY(-100%);
		transition: transform $duration-lg 0s cubic-bezier(0.5, 0, 0, 1);
		background: var(--bg-nav);
		z-index: 2;
		pointer-events: all;
		text-align: center;
		padding: 80px 0 0;
		overflow-x: hidden;

		@media (max-width: $sm - 1) {
			padding: 60px 0 0;
		}

		&-scroll {
			height: 100%;
			overflow-y: auto;
			position: relative;
		}

		&-title {
			font-size: 200px;
			font-weight: 800;
			margin: 0 auto;
			color: var(--headings1);
			position: absolute;
			pointer-events: none;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0.04;

			@media (max-width: $sm - 1) {
				font-size: 100px;
			}
		}

		&-items {
			padding: $space-xs $space-sm $space-sm;

			@media (max-width: $sm - 1) {
				padding: $space-xxs $space-xs $space-xs;
			}
		}

		&-item {
			margin: $space-xs 0 0 0;

			@media (max-width: $sm - 1) {
				margin: $space-xxs 0 0 0;
			}

			a {
				padding: $space-xs 0;
				text-transform: uppercase;
				color: var(--nav1) !important;
				display: inline-block;
				vertical-align: top;
				position: relative;
				font-size: $font-sm;
				transition: color $duration-xs;

				@media (max-width: $sm - 1) {
					font-size: $font-xxs;
				}

				&:before {
					content: '';
					position: absolute;
					height: 2px;
					bottom: 0;
					left: 50%;
					width: 0;
					transform: translateX(-50%);
					background-color: var(--nav1);
					transition: background-color $duration-xs, width $duration-sm;
				}

				&.active,
				&:hover {
					color: var(--links1) !important;

					&:before {
						width: 100%;
						background-color: var(--links1);
					}
				}
			}
		}
	}
}
