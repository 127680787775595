.TABLE {
	display: table;
	width: 100%;
	height: 100%;
	margin: 0 !important;

	&__row {
		display: table-row;
		height: 100%;
		
		&-cell {
			display: table-cell;
			height: 100%;
			float: inherit !important;
			vertical-align: middle;
		}
	}
}

.TOOLTIP {
	box-shadow: $shadow1;

	&__text {
		width: 120px;
		display: block;
		font-size: $font-xxxs;
		line-height: $lh-xxxs;
		color: var(--text1);
	}
}

.clickable {
	cursor: pointer;
}

.swiper-scrollbar {
	z-index: 0 !important;
}

.hidden {
	display: none !important;
}

.overflow-hidden {
	overflow: hidden;
}

.no-border {
	border: none !important;
}

.no-bottom-padding {
	padding-bottom: 0 !important;
}

.no-pointer-events {
	pointer-events: none;
}

.rtl {
	unicode-bidi: bidi-override; 
	direction: rtl;
}

.pre-line {
	white-space: pre-line;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.margin-top {
	margin-top: $space-sm;
}

.relative {
	position: relative;
}

.invisible {
	opacity: 0;
}

.full-height,
.h-100 {
	height: 100%;
}

.text-center {
	text-align: center !important;
}

.text-left {
	text-align: left !important;
}

.clr-green {
	color: $green !important;
}

.clr-yellow {
	color: $yellow !important;
}

.clr-red {
	color: $red !important;
}

.bg-green {
	background-color: $green !important;
}

.bg-yellow {
	background-color: $yellow !important;
}

.bg-red {
	background-color: $red !important;
}

.success {
	color: $green;

	> * {
		color: $green;
	}
	
	.CHECKBOX__box,
	.RADIO__box,
	input,
	textarea,
	select {
		border: 1px solid $green !important;
	}
}

.error {
	color: $red;

	> * {
		color: $red;
	}

	.error {
		margin: $space-xs 0 0;
	}

	.RADIO__item-label,
	.CHECKBOX__item-label {
		color: $red;
	}
	
	.CARDINPUT,
	.UPLOAD__dropzone,
	.CHECKBOX__item-box,
	.RADIO__item-box,
	input,
	textarea,
	select {
		border: 1px solid $red !important;
	}
}

.bg-facebook {
	background-color: #3b5999 !important;
}

.bg-instagram {
	background-color: #e4405f !important;
}

.bg-twitter {
	background-color: #55acee !important;
}

.bg-linkedin {
	background-color: #0077B5 !important;
}

.bg-youtube {
	background-color: #cd201f !important;
}

.bg-vimeo {
	background-color: #1ab7ea !important;
}

.bg-reddit {
	background-color: #ff5700 !important;
}

.bg-blogger {
	background-color: #f57d00 !important;
}

.bg-pinterest {
	background-color: #bd081c !important;
}

.bg-spotify {
	background-color: #1ed760 !important;
}

.bg-soundcloud {
	background-color: #ff3300 !important;
}

.bg-github {
	background-color: #000000 !important;
}

.bg-behance {
	background-color: #131418 !important;
}

.bg-flickr {
	background-color: #ff0084 !important;
}

.bg-dribbble {
	background-color: #ea4c89 !important;
}

.bg-tumblr {
	background-color: #34465d !important;
}

.bg-foursquare {
	background-color: #f94877 !important;
}

.bg-tiktok {
	background-color: #000000 !important;
}

.bg-fiverr {
	background-color: #1dbf73 !important;
}

.bg-upwork {
	background-color: #6fda44 !important;
}

.bg-freelancer {
	background-color: #29b2fe !important;
}

.clr-facebook {
	color: #3b5999 !important;
}

.clr-instagram {
	color: #e4405f !important;
}

.clr-twitter {
	color: #55acee !important;
}

.clr-linkedin {
	color: #0077B5 !important;
}

.clr-youtube {
	color: #cd201f !important;
}

.clr-vimeo {
	color: #1ab7ea !important;
}

.clr-reddit {
	color: #ff5700 !important;
}

.clr-blogger {
	color: #f57d00 !important;
}

.clr-pinterest {
	color: #bd081c !important;
}

.clr-spotify {
	color: #1ed760 !important;
}

.clr-soundcloud {
	color: #ff3300 !important;
}

.clr-github {
	color: #000000 !important;
}

.clr-behance {
	color: #131418 !important;
}

.clr-flickr {
	color: #ff0084 !important;
}

.clr-dribbble {
	color: #ea4c89 !important;
}

.clr-tumblr {
	color: #34465d !important;
}

.clr-foursquare {
	color: #f94877 !important;
}

.clr-tiktok {
	color: #000000 !important;
}

.clr-fiverr {
	color: #1dbf73 !important;
}

.clr-upwork {
	color: #6fda44 !important;
}

.clr-freelancer {
	color: #29b2fe !important;
}
