@import './../../assets/styles/settings';
@import './../../assets/styles/grid';

.CONTACTSITEM {
	p {

		@media (max-width: $sm - 1) {
			text-align: center !important;
		}
	}

	.FORM__message {
		background: var(--bg-el-normal);
		overflow: hidden;
		margin: $space-sm 0 0;
		padding: 0 $space-sm $space-sm;
		border-radius: $radius-md;

		@media (max-width: $sm - 1) {
			padding: 0 $space-xs $space-sm;
		}

		> * {
			margin: $space-sm 0 0;
			color: $light;
		}

		&.success {
			background: $green;
		}

		&.error {
			background: $red;
		}
	}
}