@import './../../assets/styles/settings';

.PAGEBG {
	position: relative;
	overflow: hidden;

	&__bg1,
	&__bg2,
	&__bg3 {
		@include aspect-ratio(1, 1);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		z-index: -1;
		position: absolute;
		width: 100%;
		// height: 600px;
	}

	&__bg1 {
		top: -20%;
		left: -50%;
		opacity: 0.3;
	}

	&__bg2 {
		top: 30%;
		right: -30%;
		z-index: -1;
		opacity: 0.3;
	}

	&__bg3 {
		top: 50%;
		right: -50%;
		opacity: 0.2;
		transform: rotate(180deg);
	}
}