@import './../../assets/styles/settings';

.INPUT {
	&__input {
		position: relative;

		&.has-icon {
			input {
				padding-left: ($space-md + $space-xs);

				@media (max-width: $sm - 1) {
					padding-left: $space-md;
				}
			}
		}

		&-icon {
			width: 20px;
			height: 20px;
			border-radius: $radius-xs;
			position: absolute;
			top: 50%;
			left: $space-sm;
			transform: translateY(-50%);

			span {
				padding: 2px;
				display: block;
				font-size: 16px;
				color: var(--placeholders1);
			}
		}

		&.password {
			input {
				padding-right: $space-md;

				@media (max-width: $sm - 1) {
					padding-right: $space-xs;
				}
			}
		}

		&-showpass {
			position: absolute;
			width: 20px;
			height: 20px;
			top: calc(50% - 10px);
			right: $space-sm;
			z-index: 1;
			cursor: pointer;

			@media (max-width: $sm - 1) {
				right: $space-xs;
			}

			&.active,
			&:hover {
				span {
					color: var(--main-clr1);
				}
			}

			span {
				font-size: 20px;
				color: var(--border2);
				transition: color $duration-xs;
			}
		}
	}
}