/*Px to rem*/
/*-----------------------------------------------------------------------------*/
$html-font-size: 16px;

@function stripUnit($value) {
	@return $value / ($value * 0 + 1);
}

@function rem($pxValue) {
	@return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

/*Main colors*/
/*-----------------------------------------------------------------------------*/
$bg1:					#f9f9f9 !default; 								// Bg1
$bg1-diff1:				#111111 !default; 								// Bg1

$bg2:					#ffffff !default; 								// Bg2
$bg2-diff1:				#000000 !default; 								// Bg2

$bg-nav:				#f9f9f9 !default; 								// Nav
$bg-nav-diff1:			#111111 !default; 								// Nav

$bg-header:				#ffffff !default; 								// Header
$bg-header-diff1:		#222222 !default; 								// Header

$bg-footer:				#ffffff !default; 								// Header
$bg-footer-diff1:		#000000 !default; 								// Header

$bg-cookies:			#f9f9f9 !default; 								// Infobar
$bg-cookies-diff1:		#111111 !default; 								// Infobar

$bg-popups:				#ffffff !default; 								// Popups
$bg-popups-diff1:		#222222 !default; 								// Popups

$overlay1:				#000000 !default;
$overlay1-diff1:		#ffffff !default;

$bg-btn-normal:			darken(#555555, 0%) !default; 					// Buttons
$bg-btn-normal-diff1:	darken(#555555, 0%) !default; 					// Buttons

$bg-btn-hover:			darken(#555555, 2%) !default; 					// Buttons hover
$bg-btn-hover-diff1:	darken(#555555, 2%) !default; 					// Buttons hover

$bg-btn-active:			darken(#555555, 4%) !default; 					// Buttons active
$bg-btn-active-diff1:	darken(#555555, 4%) !default; 					// Buttons active

$bg-el-normal:			darken(#f9f9f9, 4%) !default; 					// Elements
$bg-el-normal-diff1:	lighten(#111111, 4%) !default; 					// Elements

$bg-el-hover:			darken(#f9f9f9, 8%) !default; 					// Elements hover
$bg-el-hover-diff1:		lighten(#111111, 8%) !default; 					// Elements hover

$bg-el-active:			darken(#f9f9f9, 12%) !default; 					// Elements active
$bg-el-active-diff1:	lighten(#111111, 12%) !default; 				// Elements active

$main-clr1:				#aaaaaa !default;
$main-clr1-diff1:		#777777 !default;
$main-clr2:				#aaaaaa !default;
$main-clr2-diff1:		#777777 !default;

$yellow:				#e2b923 !default;
$green:					#50c389 !default; // #1ccc73
$red:					#db5959 !default;
$dark:					#000000 !default;
$light:					#ffffff !default;

$brown:					#c37550 !default;
$light-green:			#a1c350 !default;
$dark-green:			#50b3c3 !default;
$pink:					#c3508a !default;
$violet:				#9150c3 !default;

/*Text colors*/
/*-----------------------------------------------------------------------------*/
$text1:					#666666 !default; 								// Main body on light, p and etc.
$text1-diff1:			#dddddd !default; 								// Main body on light, p and etc.

$text2:					#aaaaaa !default; 								// Main body on dark, p and etc.
$text2-diff1:			#666666 !default; 								// Main body on dark, p and etc.

$headings1:				#333333 !default; 								// Headings on light, text
$headings1-diff1:		#eeeeee !default; 								// Headings on light, text

$headings2:				#ffffff !default; 								// Headings on dark, text
$headings2-diff1:		#333333 !default; 								// Headings on dark, text

$placeholders1:			#bbbbbb !default; 								// Headings on light, text
$placeholders1-diff1:	#bbbbbb !default; 								// Headings on light, text

$nav1:					#666666 !default; 								// Nav text
$nav1-diff1:			#dddddd !default; 								// Nav text

$buttons1:				#ffffff !default; 								// Button text
$buttons1-diff1:		#ffffff !default; 								// Button text

$links1:				#555555 !default; 								// Links
$links1-diff1:			#555555 !default; 								// Links

$links2:				lighten(#dddddd, 40%) !default; 				// Links
$links2-diff1:			lighten(#777777, 40%) !default; 				// Links

/*Border colors*/
/*-----------------------------------------------------------------------------*/
$border1:				#efefef !default; 								// Borders
$border1-diff1:			#1d1d1d !default; 								// Borders

$border2:				#c5c5c5 !default; 								// Input borders
$border2-diff1:			#333333 !default; 								// Input borders

$border3:				darken(#c5c5c5, 20%) !default; 					// Input borders
$border3-diff1:			lighten(#333333, 20%) !default; 				// Input borders

/*Shadows*/
/*-----------------------------------------------------------------------------*/
$shadow1:				0px 0px 20px 0px rgba($dark, .1) !default;			// Floating elements shadow
$shadow2:				0px 0px 5px 0px rgba($dark, .08) !default;			// Boxes shadow

/*Breakpoints*/
/*-----------------------------------------------------------------------------*/
$xs:					480px; // Mobile
$sm:					768px; // Tablet
$md:					1124px; // Laptop
$lg:					1440px; // Desktop
$xl:					1920px; // Large desktop

/*Durations*/
/*-----------------------------------------------------------------------------*/
$duration-xs: 			200ms !default;
$duration-sm: 			400ms !default;
$duration-md: 			600ms !default;
$duration-lg: 			800ms !default;
$duration-xl: 			1s !default;
$duration-xxl: 			2s !default;

/*Fonts*/
/*-----------------------------------------------------------------------------*/
$font-xxxxs:			rem(8px) !default;
$font-xxxs:				rem(10px) !default;
$font-xxs:				rem(12px) !default;
$font-xs:				rem(14px) !default;
$font-sm:				rem(16px) !default;
$font-md:				rem(18px) !default;
$font-lg:				rem(24px) !default;
$font-xl:				rem(28px) !default;
$font-xxl:				rem(32px) !default;
$font-xxxl:				rem(38px) !default;
$font-xxxxl:			rem(48px) !default;

/*Line heights*/
/*-----------------------------------------------------------------------------*/
$lh-xxxxs:				rem(14px) !default;
$lh-xxxs:				rem(16px) !default;
$lh-xxs:				rem(20px) !default;
$lh-xs:					rem(22px) !default;
$lh-sm:					rem(24px) !default;
$lh-md:					rem(28px) !default;
$lh-lg:					rem(32px) !default;
$lh-xl:					rem(40px) !default;
$lh-xxl:				rem(48px) !default;
$lh-xxxl:				rem(52px) !default;
$lh-xxxxl:				rem(54px) !default;

/*Paddings & margins*/
/*-----------------------------------------------------------------------------*/
$space-xxl:				100px !default;
$space-xl:				80px !default;
$space-lg:				60px !default;
$space-md:				40px !default;
$space-sm:				20px !default;
$space-xs:				10px !default;
$space-xxs:				5px !default;

/*Other*/
/*-----------------------------------------------------------------------------*/
$radius-xs:				10px !default;
$radius-sm:				15px !default;
$radius-md:				20px !default;
$radius-lg:				30px !default;
$radius-xl:				50% !default;

/* Mixins */
/*-----------------------------------------------------------------------------*/
@mixin aspect-ratio($width, $height) {
	position: relative;

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}

	> .aspect-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

@mixin clearfix() {
	&:before,
	&:after {
		content: '';
		display: table;
	}
	&:after {
		clear: both;
	}
}