.SECTION {
	position: relative;
	padding-top: $space-sm;
	padding-bottom: $space-md;
	border-top: 1px solid var(--border1);

	@media (max-width: $sm - 1) {
		padding-top: $space-xs;
		padding-bottom: $space-sm;
	}

	&.diff1,
	&.diff2 {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
	}

	&.diff1 {
		&:before {
			background-color: var(--bg2);
		}
	}

	&.diff2 {
		&:before {
			background-color: var(--bg1);
		}
	}

	.size0,
	&.size0 {
		padding-top: 0;
		padding-bottom: 0;

		.SECTION__inner {
			margin-top: 0;
		}
	}

	.size1,
	&.size1 {
		padding-top: 0;
		padding-bottom: $space-sm;

		@media (max-width: $sm - 1) {
			padding-bottom: $space-xs;
		}
	}

	.size2,
	&.size2 {
		padding-top: $space-lg;
		padding-bottom: $space-xl;

		@media (max-width: $sm - 1) {
			padding-top: ($space-md + $space-xs);
			padding-bottom: $space-lg;
		}
	}

	.size3,
	&.size3 {
		padding-top: ($space-lg + $space-xl);
		padding-bottom: ($space-xl + $space-xl);

		@media (max-width: $sm - 1) {
			padding-top: ($space-md + $space-xs);
			padding-bottom: $space-lg;
		}
	}

	&__inner {
		border-radius: $radius-md;
		padding-top: $space-sm;
		padding-bottom: $space-md;
		position: relative;
		margin-top: $space-sm;

		@media (max-width: $sm - 1) {
			margin-top: $space-xs;
			padding-top: $space-xs;
			padding-bottom: $space-sm;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: var(--bg2);
			z-index: -1;
			border-radius: $radius-md;
			box-shadow: $shadow2;
		}

		&.blur {
			&:before {
				opacity: 0.96;
			}

			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: -2;
				border-radius: $radius-md;
				backdrop-filter: blur(5px);
				-webkit-backdrop-filter: blur(5px);
				overflow: hidden;

				@media (max-width: $md + 219) {
					backdrop-filter: inherit;
					-webkit-backdrop-filter: inherit;
				}
			}
		}
	}

	&__content,
	&__item {
		padding-top: $space-sm;

		@media (max-width: $sm - 1) {
			padding-top: $space-xs;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: -1;
	}

	&__bg1,
	&__bg2,
	&__bg3,
	&__bg4,
	&__bg5,
	&__bg6 {
		position: absolute;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		animation: float 6s ease-in-out infinite;
	}

	&__bg1 {
		top: -200px;
		right: -100px;
		width: 500px;
		height: 500px;
		animation: float 3s ease-in-out infinite;

		@media (max-width: $md - 1) {
			top: -400px;
			right: -100px;
		}
	}

	&__bg2 {
		top: -100px;
		right: -400px;
		width: 600px;
		height: 600px;

		@media (max-width: $md - 1) {
			top: -400px;
			right: -500px;
		}
	}

	&__bg3 {
		top: 200px;
		left: 0px;
		width: 200px;
		height: 200px;
		animation: float 3s ease-in-out infinite;

		@media (max-width: $md - 1) {
			left: -100px;
		}
	}

	&__bg4 {
		top: 100px;
		left: -300px;
		width: 400px;
		height: 400px;

		@media (max-width: $md - 1) {
			left: -360px;
		}
	}

	&__bg5 {
		top: 160px;
		right: 40px;
		width: 100px;
		height: 100px;
		animation: float 3s ease-in-out infinite;
	}

	&__bg6 {
		top: 200px;
		right: -150px;
		width: 200px;
		height: 200px;
	}

	&__icon {
		margin: $space-sm auto 0;
		background: var(--bg2);
		box-shadow: $shadow2;
		width: 160px;
		height: 160px;
		border-radius: $radius-md;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: $sm - 1) {
			margin: $space-xs auto 0;
			width: 120px;
			height: 120px;
		}
		
		[class*="icon-"] {
			font-size: 80px;
			width: 80px;
			height: 80px;
			display: inline-block;
			vertical-align: top;
			color: var(--text2);

			@media (max-width: $sm - 1) {
				font-size: 60px;
				width: 60px;
				height: 60px;
			}
		}
	}

	&__image {
		overflow: hidden;
		border-radius: $radius-md;
		position: relative;
		@include aspect-ratio(16, 9);
		margin: $space-sm 0 0;
		display: block;

		@media (max-width: $sm - 1) {
			margin: $space-xs 0 0;
		}

		.PROGRESSIVEIMG {
			img {
				position: absolute;
				width: 100%;
				height: auto;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	&__pretitle {
		margin: $space-sm 0 0;

		@media (max-width: $sm - 1) {
			margin: $space-xs 0 0;
			text-align: center;
		}

		span {
			display: inline-block;
			padding: 0 $space-sm;
			line-height: 30px;
			color: $light;
			font-size: $font-xs;
			position: relative;
			z-index: 1;
			color: var(--text2);

			@media (max-width: $sm - 1) {
				font-size: $font-xxs;
				line-height: 20px;
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 50%;
				height: 2px;
				z-index: -1;
				background: var(--text2);
			}

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 50%;
				height: 2px;
				z-index: -2;
				background: var(--text2);
			}
		}
	}

	&__title {
		margin: $space-sm 0 0;
		font-size: $font-xl;
		line-height: $lh-xl;
		color: var(--headings1);
		font-weight: bold;

		@media (max-width: $sm - 1) {
			text-align: center;
			margin: $space-xs 0 0;
			font-size: $font-lg;
			line-height: $lh-lg;
		}

		&.diff1 {
			font-size: $font-xxl;
			line-height: $lh-xxl;

			@media (max-width: $sm - 1) {
				font-size: $font-xl;
				line-height: $lh-xl;
			}
		}

		&.diff2 {
			font-size: $font-lg;
			line-height: $lh-lg;

			@media (max-width: $sm - 1) {
				font-size: $font-md;
				line-height: $lh-md;
			}
		}

		&.diff3 {
			font-size: $font-xxxl;
			line-height: $lh-xxxl;

			@media (max-width: $sm - 1) {
				font-size: $font-xxl;
				line-height: $lh-xxl;
			}
		}

		&.diff4 {
			font-size: $font-md;
			line-height: $lh-md;

			@media (max-width: $sm - 1) {
				font-size: $font-sm;
				line-height: $lh-sm;
			}
		}

		span {
			color: var(--main-clr2);	
		}
	}

	&__subtitle {
		margin: $space-xs 0 0;
		font-size: $font-md;
		line-height: $lh-md;
		font-weight: 300;
		color: var(--text1);

		@media (max-width: $sm - 1) {
			text-align: center;
			font-size: $font-sm;
			line-height: $lh-sm;
			margin: $space-xxs 0 0;
		}

		&.diff1 {
			font-size: $font-xs;
			line-height: $lh-xs;
		}

		&.diff2 {
			font-size: $font-lg;
			line-height: $lh-lg;
		}
	}
}