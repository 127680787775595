.BUTTON {
	font-family: "Font", "Montserrat", sans-serif !important;
	font-size: $font-xxxs;
	outline: none;
	margin: $space-sm 0 0;
	color: var(--buttons1);
	border-radius: $radius-lg;
	text-align: center;
	position: relative;
	-webkit-appearance: none;
	display: inline-block;
	appearance: none;
	cursor: pointer;
	z-index: 1;
	letter-spacing: 1px;
	vertical-align: middle;
	font-weight: 400;
	text-transform: uppercase;
	width: 100%;
	white-space: nowrap;
	padding: ($space-xs - 1px) $space-sm;
	line-height: $lh-xxs !important;
	user-select: none;
	height: auto;
	border: none;
	overflow: hidden;
	background: transparent;

	@media (max-width: $sm - 1) {
		margin: $space-xs 0 0;
		font-size: $font-xxxxs;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
		background: var(--bg-btn-normal);
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
		opacity: 0;
		z-index: -1;
		transition: opacity $duration-xs, background-color $duration-xs;
	}

	&.diff1 {
		color: var(--text1);

		&:before {
			background: var(--bg-el-normal);
		}

		&:not([disabled]),
		&:not(.disabled),
		&:not(disabled) {
			&:hover:after {
				opacity: 1;
				background: var(--bg-el-hover);
			}

			&:active:after,
			&.active:after {
				opacity: 1;
				background: var(--bg-el-active);
			}
		}
	}

	&:not([disabled]),
	&:not(.disabled),
	&:not(disabled) {
		&:hover:after {
			opacity: 1;
			background: var(--bg-btn-hover);
		}

		&:active:after,
		&.active:after {
			opacity: 1;
			background: var(--bg-btn-active);
		}
	}

	&[disabled],
	&.disabled,
	&:disabled {
		opacity: 0.4;
		cursor: default;
		pointer-events: none;
	}

	&.toggled {
		opacity: 0.4;
	}

	&.diff2 {
		padding: ($space-xxs - 1px) $space-xs;
		border-radius: $radius-xs;
	}
}