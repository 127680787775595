@import './../../assets/styles/settings';

.PROGRESSIVEIMG {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;

	&.active {
		.PROGRESSIVEIMG__item {
			filter: none;
		}
	}

	&__item {
		filter: blur(20px);
		transition: filter $duration-md;
		width: 100%;
		height: 100%;
		display: block;
		
		&.bg {
			position: absolute;
			top: 0;
			left: 0;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	}
}