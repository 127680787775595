@import './../../assets/styles/settings';

.POPUP {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 6;
	display: none;
	text-align: left; 
	overflow-y: auto;
	opacity: 0;

	&.active {
		display: block;
		opacity: 0;
		animation: fadeIn2 $duration-sm;
		animation-fill-mode: forwards;

		.POPUP__inner {
			// transform: scale(1);
			// animation: scaleUp1 $duration-sm cubic-bezier(0.4, 0, 0.2, 1);
			// animation-fill-mode: forwards;
		}
	}

	.grid {
		@media (max-width: $sm - 1) {
			padding: 0 $space-sm;
		}
	}

	.LOADER {
		border-radius: $radius-md;
	}

	&__table {
		display: table;
		height: 100%;
		width: 100%;
		position: relative;
		
		&-cell {
			display: table-cell;
			vertical-align: middle;
			padding: $space-md 0;

			@media (max-width: $sm - 1) {
				padding: $space-sm 0;
			}
		}	
	}

	&__closeoverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.2;
		background: var(--overlay1);
	}

	&__inner {
		width: 100%;
		background: var(--bg-popups);
		position: relative;
		border-radius: $radius-md;
		margin: 0 auto;
		box-shadow: $shadow1;
		padding: 0 0 $space-md;
		// transform: scale(0);

		@media (max-width: $sm - 1) {
			padding: 0 0 $space-sm;
		}
	}

	&__bgs {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: $radius-md;
		
		&-bg1,
		&-bg2 {
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			@include aspect-ratio(1, 1);
			position: absolute;
			z-index: 0;
		}
	
		&-bg1 {
			top: -300px;
			right: -260px;
			width: 400px;
		}

		&-bg2 {
			top: -50px;
			right: -380px;
			width: 400px;
		}
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		text-align: right;
		z-index: 1;
		padding: 34px $space-md;

		@media (max-width: $sm - 1) {
			padding: 14px $space-xs 0 0;
		}

		&-item {
			width: 40px;
			height: 40px;
			display: inline-block;
			vertical-align: top;
			font-size: $font-md;
			cursor: pointer;
			transition: background-color $duration-xs;
			z-index: 2;
			padding: $space-xs;
			background: var(--bg-el-normal);
			border-radius: $radius-xl;

			&:hover {
				background: var(--bg-el-hover);
			}

			&:active {
				background: var(--bg-el-active);
			}

			[class*="icon-"] {
				font-size: 20px;
				width: 20px;
				height: 20px;
				display: block;
				color: var(--text1);
				transform: rotate(45deg);
				transition: color $duration-xs;
			}
		}
	}

	&__content {
		position: relative;

		.no-bottom-padding.SECTION {
			border: none;
		}
	}

	&__title {
		font-size: $font-sm;
		line-height: $lh-sm;
		color: var(--headings1);
		font-weight: bold;
		margin: $space-sm 0 0;
		padding: 0 $space-xl 0 0;
		text-transform: uppercase;

		@media (max-width: $sm - 1) {
			margin: $space-xs 0 0;
			padding: 0 $space-lg 0 0;
		}
	}
	
	&__messages {
		margin: $space-sm 0 0;

		@media (max-width: $sm - 1) {
			margin: $space-xs 0 0; 
		}

		p {
			margin: $space-xs 0 0;
		}
	}

	@keyframes fadeIn2 {
		0% {
			opacity: 0;
		} 100% {
			opacity: 1;
		}
	}

	// @keyframes scaleUp1 {
	// 	0% {
	// 		transform: scale(0);
	// 	} 100% {
	// 		transform: scale(1);
	// 	}
	// }
}