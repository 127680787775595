@import './../../assets/styles/settings';
@import './../../assets/styles/animations';

.PROJECT {
	&__details {
		overflow: hidden;
		background: var(--bg2);
		box-shadow: $shadow2;
		border-radius: $radius-md;
		padding: ($space-sm + $space-xs) $space-md $space-md;
		height: 100%;

		@media (max-width: $sm - 1) {
			padding: $space-xs $space-sm $space-sm;
		}

		&-item {
			display: inline-block;
			vertical-align: top;
			margin: $space-xs $space-xxs 0;
			padding: 0 $space-sm;
			background: var(--bg-el-normal);
			border-radius: $radius-md;
			font-size: $font-xs;
			line-height: $lh-sm;
			color: $text1;

			&:nth-child(5n + 1) {
				@extend .delay-xs;
			}

			&:nth-child(5n + 2) {
				@extend .delay-sm;
			}

			&:nth-child(5n + 3) {
				@extend .delay-md;
			}

			&:nth-child(5n + 4) {
				@extend .delay-lg;
			}

			&:nth-child(5n + 5) {
				@extend .delay-xl;
			}
		}
	}

	&__slider {
		// overflow: hidden;

		.swiper-slide {
			&:nth-child(5n + 1) {
				.PROJECT__slider-item {
					@extend .delay-xs;
				}
			}

			&:nth-child(5n + 2) {
				.PROJECT__slider-item {
					@extend .delay-sm;
				}
			}

			&:nth-child(5n + 3) {
				.PROJECT__slider-item {
					@extend .delay-md;
				}
			}

			&:nth-child(5n + 4) {
				.PROJECT__slider-item {
					@extend .delay-lg;
				}
			}

			&:nth-child(5n + 5) {
				.PROJECT__slider-item {
					@extend .delay-xl;
				}
			}
		}

		&-item {
			box-shadow: $shadow2;
			border-radius: $radius-md;

			.PROGRESSIVEIMG {
				border-radius: $radius-md;
				@include aspect-ratio(1, 1);

				&__item {
					background-position: center top;
					cursor: pointer;
					transform: scale(1);
					transition: transform $duration-sm;

					&:hover {
						transform: scale(1.1);
					}
				}
			}

		}
	}
}