@import './../../assets/styles/settings';

.LOADER {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	display: none;
	overflow: hidden;
	transition: all 500ms;
	z-index: 2;

	&:before {
		content: "";
		background: var(--bg1);
		opacity: 0.96;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&.fixed {
		position: fixed;
		z-index: 20;

		&.active {
			display: block;
			animation: fadeIn1 $duration-sm;
			animation-fill-mode: forwards;
		}
	}

	&.no-bg {
		&:before {
			background: transparent;
		}
	}

	&:not(.fixed).active {
		display: block;
		opacity: 1;
	}
	
	&__item {
		width: 20px;
		height: 20px;
		border-top: 2px solid transparent;
		border-right: 2px solid var(--main-clr1);
		border-bottom: 2px solid var(--main-clr1);
		border-left: 2px solid var(--main-clr1);
		border-radius: 50%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		animation: spin 500ms infinite linear;
		display: block;
	}

	@keyframes fadeIn1 {
		0% {
			opacity: 0;
		} 100% {
			opacity: 0.9;
		}
	}
	
	@keyframes spin {
		from {
			transform: rotate(0deg);
		} to {
			transform: rotate(360deg);
		}
	}
}