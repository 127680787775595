* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-overflow-scrolling: touch;
	margin: 0;
	padding: 0;
	
	&:focus {
		outline: none;
	}
}

body {
	--bg1: #{$bg1};
	--bg2: #{$bg2};
	--bg-nav: #{$bg-nav};
	--bg-header: #{$bg-header};
	--bg-footer: #{$bg-footer};
	--bg-cookies: #{$bg-cookies};
	--bg-popups: #{$bg-popups};
	--overlay1: #{$overlay1};
	--bg-btn-normal: #{$bg-btn-normal};
	--bg-btn-hover: #{$bg-btn-hover};
	--bg-btn-active: #{$bg-btn-active};
	--bg-el-normal: #{$bg-el-normal};
	--bg-el-hover: #{$bg-el-hover};
	--bg-el-active: #{$bg-el-active};
	--main-clr1: #{$main-clr1};
	--main-clr2: #{$main-clr2};
	--text1: #{$text1};
	--text2: #{$text2};
	--headings1: #{$headings1};
	--headings2: #{$headings2};
	--placeholders1: #{$placeholders1};
	--nav1: #{$nav1};
	--buttons1: #{$buttons1};
	--links1: #{$links1};
	--links2: #{$links2};
	--border1: #{$border1};
	--border2: #{$border2};
	--border3: #{$border3};
	
	&.dark-theme {
		--bg1: #{$bg1-diff1};
		--bg2: #{$bg2-diff1};
		--bg-nav: #{$bg-nav-diff1};
		--bg-header: #{$bg-header-diff1};
		--bg-footer: #{$bg-footer-diff1};
		--bg-cookies: #{$bg-cookies-diff1};
		--bg-popups: #{$bg-popups-diff1};
		--overlay1: #{$overlay1-diff1};
		--bg-btn-normal: #{$bg-btn-normal-diff1};
		--bg-btn-hover: #{$bg-btn-hover-diff1};
		--bg-btn-active: #{$bg-btn-active-diff1};
		--bg-el-normal: #{$bg-el-normal-diff1};
		--bg-el-hover: #{$bg-el-hover-diff1};
		--bg-el-active: #{$bg-el-active-diff1};
		--main-clr1: #{$main-clr1-diff1};
		--main-clr2: #{$main-clr2-diff1};
		--text1: #{$text1-diff1};
		--text2: #{$text2-diff1};
		--headings1: #{$headings1-diff1};
		--headings2: #{$headings2-diff1};
		--placeholders1: #{$placeholders1-diff1};
		--nav1: #{$nav1-diff1};
		--buttons1: #{$buttons1-diff1};
		--links1: #{$links1-diff1};
		--links2: #{$links2-diff1};
		--border1: #{$border1-diff1};
		--border2: #{$border2-diff1};
		--border3: #{$border3-diff1};
	}

	font-family: "Font", "Montserrat", sans-serif !important;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	font-weight: normal;
	color: var(--text1);
	letter-spacing: 0.01em;
	background: var(--bg1);
	color: var(--text1);

	&.overflow {
		overflow: hidden;
	}
}


strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

p {
	margin: $space-sm 0 0;
	line-height: $lh-xs;
	font-size: $font-xs;
	word-break: break-word;
	color: var(--text1);
	font-weight: 200;

	@media (max-width: $sm - 1) {
		margin: $space-xs 0 0;
	}
}

img {
	max-width: 100%;
}

ul, ol {
	margin: 0;
	margin: ($space-xs + $space-xxs) 0 0 $space-sm;
	padding: 0 0 0 10px;

	@media (max-width: $sm - 1) {
		margin: $space-xxs 0 0 $space-xs;
	}

	&.no-bulletpoints {
		list-style-type: none;
		margin: 0;
	}

	ul, ol {
		margin: 0 0 0 $space-sm;

		@media (max-width: $sm - 1) {
			margin: 0 0 0 $space-xs;
		}
	}
}

li {
	line-height: $lh-xxs;
	font-size: $font-xxs;
	margin: $space-xxs 0 0;
}

a,
.AHREF {
	text-decoration: none;
	color: var(--links1);
	cursor: pointer;
	font-weight: normal;
	position: relative;
	display: inline-block;
	vertical-align: top;
	font-size: $font-xxs;
}

.LINK {
	text-decoration: none;
	color: var(--links1);
	cursor: pointer;
	position: relative;
	display: inline-block;
	vertical-align: top;
	user-select: none;
    font-weight: 500;

	&:hover {
		text-decoration: underline;
	}

	&.diff1 {
		color: $light;
	}

	&.diff2 {
		color: $red;
	}

	&.size1 {
		font-size: $font-xxxs;
	}
}

h1,
h2, h3,
h4, h5, h6 {
	margin: $space-sm 0 0;
	font-weight: bold;
	font-size: $font-xxs;
	line-height: $lh-xxs;
	color: var(--headings1);

	@media (max-width: $sm - 1) {
		margin: $space-xs 0 0;
	}
}

h1 {
	font-size: $font-lg;
	line-height: $lh-lg;

	@media (max-width: $sm - 1) {
		font-size: $font-md;
		line-height: $lh-md;
	}
}

h2 {
	font-size: $font-md;
	line-height: $lh-md;

	@media (max-width: $sm - 1) {
		font-size: $font-sm;
		line-height: $lh-sm;
	}
}

h3 {
	font-size: $font-sm;
	line-height: $lh-sm;

	@media (max-width: $sm - 1) {
		font-size: $font-xs;
		line-height: $lh-xs;
	}
}

h4 {
	font-size: $font-xs;
	line-height: $lh-xs;

	@media (max-width: $sm - 1) {
		font-size: $font-xxs;
		line-height: $lh-xxs;
	}
}

h5 {
	font-size: $font-xxs;
	line-height: $lh-xxs;
}

h6 {
	font-size: $font-xxs;
	line-height: $lh-xxs;
}

table {
	border-collapse: collapse;
	width: 100%;
}

input,
button,
textarea,
.CARDINPUT,
select {
	outline: none !important;
}

blockquote {
	padding: 0 0 $space-sm $space-sm;
	overflow: hidden;
	margin: $space-sm 0 0;
	border-left: $space-xs solid $bg-el-normal;
	font-style: italic;
}

figcaption {
	margin: $space-xs 0 0;
	font-size: $font-xxxs;
	font-weight: bold;
}

label {
	margin: 0 0 $space-xxs;
	display: inline-block;
	vertical-align: top;
	line-height: $lh-xxs;
	font-size: $font-xxs;

	[class*="icon-question-mark"] {
		display: inline-block;
		vertical-align: top;
		width: 16px;
		height: 16px;
		font-size: 8px;
		padding: 4px 0;
		text-align: center;
		border-radius: $radius-xl;
		background: var(--text2);
		color: $light;
		margin: 0 0 0 $space-xxs;
		cursor: pointer;
	}
}

input,
textarea,
.CARDINPUT,
select {
	width: 100%;
	border: 1px solid var(--border2);
	vertical-align: top;
	transition: border $duration-sm;
	padding: ($space-xs - 2px) $space-sm;
	font-family: "Font", "Arial", sans-serif !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	border-radius: $radius-md;
	font-size: $font-xxs;
	line-height: $lh-xxs;
	background: var(--bg1);
	color: var(--text1);

	@media (max-width: $sm - 1) {
		padding: ($space-xs - 2px) $space-xs;
	}
	
	&:hover,
	&:focus {
		border: 1px solid var(--border3);
	}
}

textarea {
	height: 80px;
	min-height: 140px;
	resize: vertical;
	padding: $space-xs $space-sm;
}

select::-ms-expand {
	display: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}

::-webkit-input-placeholder {
	font-family: "Font", "Arial", sans-serif !important;
	color: $placeholders1;
	font-size: $font-xxs;
	line-height: $lh-xxs;
}

::-moz-placeholder {
	font-family: "Font", "Arial", sans-serif !important;
	color: $placeholders1;
	font-size: $font-xxs;
	line-height: $lh-xxs;
}

:-ms-input-placeholder {
	font-family: "Font", "Arial", sans-serif !important;
	color: $placeholders1;
	font-size: $font-xxs;
	line-height: $lh-xxs;
}

:-moz-placeholder {
	font-family: "Font", "Arial", sans-serif !important;
	color: $placeholders1;
	font-size: $font-xxs;
	line-height: $lh-xxs;
}
