@import './../../assets/styles/settings';

.PAGINATION {
	margin: 0 (0 - $space-xxs);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-end;

	&__prev,
	&__next {
		display: inline-block;
		vertical-align: top;
		line-height: 20px;
		padding: $space-xxs $space-sm;
		border-radius: $radius-lg;
		margin: $space-sm $space-xxs 0;
		cursor: pointer;
		user-select: none;
		color: var(--text1);
		white-space: nowrap;
		overflow: hidden;
		position: relative;
		background: var(--bg-el-normal);
		transition: background-color $duration-xs;
		font-size: $font-sm;

		@media (max-width: $sm - 1) {
			margin: $space-xs $space-xxs 0; 
		}

		&:hover {
			background: var(--bg-el-hover);
		}

		&.active,
		&:active {
			background: var(--bg-el-active);
		}
	}

	&__items {
		display: inline-block;
		vertical-align: top;

		> span {
			display: inline-block;
			vertical-align: top;
		}

		&-divider {
			display: inline-block;
			vertical-align: top;
			line-height: 20px;
			padding: $space-xxs $space-xs;
			border-radius: $radius-sm;
			user-select: none;
			margin: $space-sm $space-xxs 0;
			color: var(--text1);
		}

		&-page {
			display: inline-block;
			vertical-align: top;
			line-height: 20px;
			padding: $space-xxs $space-xs;
			border-radius: $radius-lg;
			cursor: pointer;
			user-select: none;
			margin: $space-sm $space-xxs 0;
			color: var(--text1);
			position: relative;
			overflow: hidden;
			background: var(--bg-el-normal);
			transition: background-color $duration-xs;
			font-size: $font-sm;

			&:hover {
				background: var(--bg-el-hover);
			}

			&.active,
			&:active {
				background: var(--bg-el-active);
			}
		}
	}
}