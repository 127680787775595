@import './../../assets/styles/settings';
@import './../../assets/styles/animations';

.PROJECTS {
	&__child {
		margin-top: $space-md;
		
		&:nth-child(5n + 1) {
			.PROJECTS__item {
				@extend .delay-xs;
			}
		}

		&:nth-child(5n + 2) {
			.PROJECTS__item {
				@extend .delay-sm;
			}
		}

		&:nth-child(5n + 3) {
			.PROJECTS__item {
				@extend .delay-md;
			}
		}

		&:nth-child(5n + 4) {
			.PROJECTS__item {
				@extend .delay-lg;
			}
		}

		&:nth-child(5n + 5) {
			.PROJECTS__item {
				@extend .delay-xl;
			}
		}
	}

	&__item {
		height: 100%;
		
		&-inner {
			height: 100%;
			position: relative;
			text-align: center;
			overflow: hidden;
			box-shadow: $shadow2;
			transform: scale(1);
			transition: transform $duration-lg;
			border-radius: $radius-sm;
			background: $light;

			&:hover {
				transform: scale(1.02);
			}
		}

		&-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		&-image {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			@include aspect-ratio(10, 8);

			&-status {
				position: absolute;
				top: $space-sm;
				right: 0;
				background: green;
				display: inline-block;
				color: $light;
				vertical-align: top;
				padding: ($space-xxs + 2) $space-sm $space-xxs;
				border-top-left-radius: $radius-md;
				border-bottom-left-radius: $radius-md;

				&.completed {
					background: $green;
				}

				&.in-progress {
					background: $yellow;
				}

				&.queued {
					background: $red;
				}
			}
		}

		&-text {
			padding: 0 $space-md $space-md;
			background: var(--main-clr2);
			overflow: hidden;
			width: 100%;
			height: 100%;

			@media (max-width: $sm - 1) {
				padding: 0 $space-sm $space-sm;
			}

			&-title {
				color: $headings2;
				display: block;

				@media (max-width: $sm - 1) {
					margin: $space-sm 0 0;
				}
			}

			&-subtitle {
				color: var(--main-clr2);
				line-height: 40px;

				@media (max-width: $sm - 1) {
					margin: $space-sm 0 0 0;
				}
			}

			p {
				color: $text1;
			}

			&-button {
				margin-top: $space-md;
				position: relative;
				z-index: 2;

				.BUTTON {
					background: $bg-el-normal;
					color: $headings1;

					&:hover {
						box-shadow: 0px 0px 20px 0px rgba($bg-el-normal, 1);
					}
				}
			}
		}
	}
}