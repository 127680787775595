.lg-actions .lg-prev {
	background: none !important;
	font-size: 40px;
	padding: 0;
	margin-top: -10px;
	transform: rotate(180deg);

	&:after {
		font-family: 'icomoon';
		content: '\e900' !important;
	}
}

.lg-actions .lg-next {
	background: none !important;
	font-size: 40px;
	padding: 0;
	margin-top: -10px;

	&:before {
		font-family: 'icomoon';
		content: '\e900' !important;
	}
}

.swiper {
	position: relative !important;
	overflow: visible !important;
}

.swiper-slide {
	height: auto;
}

.swiper-scrollbar {
	left: inherit !important;
	bottom: inherit !important;
	top: inherit !important;
	right: inherit !important;
	position: relative !important;
	width: 100% !important;
	height: $space-xs !important;
	background: var(--border1) !important;
	margin: $space-sm auto 0 !important;
	max-width: 200px !important;
	z-index: 1 !important;

	.swiper-scrollbar-drag {
		cursor: move; /* fallback if grab cursor is unsupported */
		cursor: grab;
		background: var(--main-clr2);
		opacity: 0.8;
	}
}

// .swiper-container {
// 	width: 100%;
// }

// .swiper-wrapper {
// 	-webkit-transform: translate3d(0, 0, 0);
// 	-moz-transform: translate3d(0, 0, 0);
// 	-ms-transform: none;
// 	-o-transform: translate3d(0, 0, 0);
// 	transform: none;
// }

// .swiper-button-disabled {
// 	display: none !important;
// }

// .swiper-pagination {
// 	line-height: 0 !important;
// 	bottom: 20px !important;
// }

// .swiper-pagination-bullet {
// 	background: $bg-el-normal !important;
// 	transition: all $duration-sm !important;
// 	opacity: 0.4 !important;

// 	&-active {
// 		opacity: 1 !important;
// 	}
// }

// .oc-text-gray {
// 	color: lighten($text1, 20%);
// }

// [data-dismiss] {
// 	display: none !important;
// }

// .lightbox,
// .lightbox-image {
// 	display: block;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// }

// .fb_dialog {
// 	bottom: 20px !important;
// 	right: 20px !important;
// 	width: 60px !important;
// 	height: 60px !important;
// 	box-shadow: 0px 0px 20px 0px rgba(47, 157, 180, 0) !important;
// 	transition: all $duration-sm !important;
// 	pointer-events: all;
// 	outline: none !important;

// 	&:hover {
// 		box-shadow: 0px 0px 20px 0px rgba(47, 157, 180, 1) !important;
// 	}
// }

// .fb_customer_chat_bounce_in_v2 {
// 	z-index: 5 !important;
// }

// .alert-success {
// 	color: $green;

// 	p {
// 		font-size: $font-md;
// 	}
// }

// .alert-danger {
// 	color: $red;

// 	h4 {
// 		font-size: $font-md;
// 	}
// }

// #contactsForm_forms_flash {
// 	.alert {
// 		padding: 0 $space-md $space-md;
// 		margin: $space-sm 0 0 0;
// 		border: none !important;
// 		overflow: hidden;
// 		border-radius: $radius-md !important;
// 	}

// 	p {
// 		margin: $space-md 0 0;
// 	}
// }

// .grecaptcha-badge {
// 	display: none !important;
// }