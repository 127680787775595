@import './../../assets/styles/settings';

.TEXTAREA {
	
	&__charcount {
		font-size: $font-xxs;
		text-align: right;
		margin: $space-xs 0 0;
		font-weight: bold;

		&.red {
			color: $red;
		}

		&.green {
			color: $green;
		}
	}
}