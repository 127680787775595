@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba($main-clr2, 1);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba($main-clr2, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba($main-clr2, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba($main-clr2, 1);
		box-shadow: 0 0 0 0 rgba($main-clr2, 1);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba($main-clr2, 0);
		box-shadow: 0 0 0 10px rgba($main-clr2, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba($main-clr2, 0);
		box-shadow: 0 0 0 0 rgba($main-clr2, 0);
	}
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
	}
}

.elFadeIn {
	opacity: 0;
	position: relative;
	transition: opacity 0s 1s;

	&.animationTriggered {
		opacity: 1;
	}
}

.elFadeDown {
	opacity: 0;
	transform: translateY(-100px) !important;
	position: relative;
	transition: transform 0s 1s, opacity 0s 1s;

	&.animationTriggered {
		opacity: 1;
		transform: inherit !important;
	}
}

.bgFadeDown {
	opacity: 0;
	background-position: center 100% !important;
	position: relative;
	transition: transform 0s 1s, opacity 0s 1s;

	&.animationTriggered {
		opacity: 1;
		background-position: center 50% !important;
	}
}

.elFadeUp {
	opacity: 0;
	transform: translateY(100px) !important;
	position: relative;
	transition: transform 0s 1s, opacity 0s 1s;

	&.animationTriggered {
		opacity: 1;
		transform: inherit !important;
	}
}

.bgFadeUp {
	opacity: 0;
	background-position: center -100% !important;
	position: relative;
	transition: transform 0s 1s, opacity 0s 1s;

	&.animationTriggered {
		opacity: 1;
		background-position: center 50% !important;
	}
}

.elFadeRight {
	opacity: 0;
	transform: translateX(-100px) !important;
	position: relative;
	transition: transform 0s 1s, opacity 0s 1s;

	&.animationTriggered {
		opacity: 1;
		transform: inherit !important;
	}
}

.elFadeLeft {
	opacity: 0;
	// transform: translateX(100px) !important;
	left: 100px;
	position: relative;
	transition: transform 0s 1s, opacity 0s 1s;

	&.animationTriggered {
		opacity: 1;
		left: inherit !important;
	}
}

.no-fade {
	opacity: 1 !important;
}

.delay-xs {
	transition: 
		transform $duration-lg $duration-xs, 
		top $duration-lg $duration-xs, 
		right $duration-lg $duration-xs, 
		opacity $duration-lg $duration-xs;
}

.delay-sm {
	transition: 
		transform $duration-lg $duration-sm, 
		top $duration-lg $duration-sm, 
		right $duration-lg $duration-sm, 
		opacity $duration-lg $duration-sm;
}

.delay-md {
	transition: 
		transform $duration-lg $duration-md, 
		top $duration-lg $duration-md, 
		right $duration-lg $duration-md, 
		opacity $duration-lg $duration-md;
}

.delay-lg {
	transition: 
		transform $duration-lg $duration-lg, 
		top $duration-lg $duration-lg, 
		right $duration-lg $duration-lg, 
		opacity $duration-lg $duration-lg;
}

.delay-xl {
	transition: 
		transform $duration-lg $duration-xl, 
		top $duration-lg $duration-xl, 
		right $duration-lg $duration-xl, 
		opacity $duration-lg $duration-xl;
}

.delay-xxl {
	transition: 
		transform $duration-lg $duration-xxl, 
		top $duration-lg $duration-xxl, 
		right $duration-lg $duration-xxl, 
		opacity $duration-lg $duration-xxl;
}

.animationInactive {
	transition: 
		transform $duration-md, 
		top $duration-md, 
		right $duration-md, 
		opacity $duration-md;
}