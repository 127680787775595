.FIELD {
	margin: $space-sm 0 0;
	text-align: left;

	@media (max-width: $sm - 1) {
		margin: $space-xs 0 0;
	}

	&__heading {
		font-size: $font-md;
	}

	&__explanation {
		margin-top: 20px;
		line-height: 22px;
		font-size: 14px;
		padding: 0 $space-sm $space-sm;
		overflow: hidden;
		background: var(--bg2);
		box-shadow: $shadow2;
		border-radius: 20px;
		margin: $space-sm 0 0;
		color: $light;

		@media (max-width: $sm - 1) {
			padding: 0 $space-xs $space-sm;
		}
	}

	&__note {
		> * {
			font-size: $font-xxxs;
			line-height: $lh-xxxs;
		}
	}

	label {
		[class*="icon-"] {
			display: inline-block;
			vertical-align: text-bottom;
			font-size: 14px;
			margin: 0 0 0 $space-xs;
			color: $green;
			cursor: pointer;
			width: 14px;
			height: 14px;
		}
	}
}