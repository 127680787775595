@import './../../assets/styles/settings';

.PRICING {
	&__table {
		width: 100%;
		background: var(--bg2);
		box-shadow: $shadow2;
		overflow-x: auto;
		padding: 0 $space-xs $space-sm;
		border-radius: $radius-md;

		@media (max-width: $sm - 1) {
			padding: 0 $space-xxs $space-xs;
		}

		&-inner {
			display: table;
			width: 100%;
		}

		&-row {
			display: table-row;
			width: 100%;

			&-cell {
				display: table-cell;
				min-width: 100px;
				padding: $space-xs $space-sm $space-xs;
				text-align: left;

				@media (max-width: $sm - 1) {
					padding: $space-xxs $space-xs $space-xxs;
				}

				&:first-child {
					text-align: left;
					font-weight: bold;
				}
			}
		}
	}

	&__notes {
		padding: $space-xs $space-md $space-sm;
		
		@media (max-width: $sm - 1) {
			padding: $space-xs $space-sm $space-sm;
		}

		> * {
			font-size: $font-xxxxs;
			line-height: 18px;
			letter-spacing: initial;
		}
	}

	&__title {
		margin: $space-sm 0 0;
		font-size: $font-md;
		padding: $space-sm $space-md $space-md;
		color: var(--main-clr2);

		@media (max-width: $sm - 1) {
			text-align: left;
			margin: $space-xs 0 0;
			padding: 0 $space-sm $space-md;
		}
	}

	&__heading {
		margin: $space-xs 0 0;
		font-size: $font-xs;
		text-align: left;
	}

	&__price {
		margin: $space-xs 0 0;
		font-size: $font-md;
		text-align: left;

		> span {
			font-size: $font-xs;
			display: block;
			line-height: 20px;
			margin: $space-xs 0 0;
			color: var(--main-clr2);
		}
	}

	&__text {
		margin: $space-xs 0 0;
		font-size: $font-xxs;
		line-height: 20px;
	}

	&__icon {
		text-align: center;

		[class*="icon-"] {
			font-size: $font-sm;
		}

		&.tick {
			> * {
				color: $green;
			}
		}

		&.cross {
			> * {
				color: $red;
			}
		}
	}
}