$breakpoint-xs: $xs;
$breakpoint-sm: $sm;
$breakpoint-md: $md;
$breakpoint-lg: $lg;

$grid-max-width: $md;
$grid-spacing: $space-md;
$col-spacing: $space-xs;
$grid-spacing-mobile: $space-xs;
$col-spacing-mobile: $space-xxs;

.grid {
	max-width: $grid-max-width !important;
}

.grid-fluid,
.grid {
	width: 100%;
	max-width: $xl;
	margin: 0 auto;
	padding-left: $grid-spacing;
	padding-right: $grid-spacing;

	@media (max-width: $breakpoint-sm - 1) {
		padding-left: $grid-spacing-mobile !important;
		padding-right: $grid-spacing-mobile !important;
	}

	.row {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-left: (0 - $col-spacing);
		margin-right: (0 - $col-spacing);

		@media (max-width: $breakpoint-sm - 1) {
			margin-left: (0 - $col-spacing-mobile);
			margin-right: (0 - $col-spacing-mobile);
		}

		&.vertical-aligned {
			align-items: center;
		}

		&.reversed-cols {
			@media (min-width: $breakpoint-sm) {
				flex-direction: row-reverse;
			}
		}

		&.centered-items {
			> * {
				margin: 0 auto;
			}
		}

		&.equal-height {
			> * {
				height: 100%;
			}
		}
	}

	[class*="grid"],
	[class*="col-"],
	.grid:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	[class*="col-"] {
		min-height: 1px;
		padding-left: $col-spacing;
		padding-right: $col-spacing;

		@media (max-width: $breakpoint-sm - 1) {
			padding-left: $col-spacing-mobile;
			padding-right: $col-spacing-mobile;
		}
	}

	// ----------------------------------------------------

	.col-12 {
		width: 100%;
	}

	.col-11 {
		width: 91.66%;
	}

	.col-10 {
		width: 83.33%;
	}

	.col-9 {
		width: 75%;
	}

	.col-8 {
		width: 66.66%;
	}

	.col-7 {
		width: 58.33%;
	}

	.col-6 {
		width: 50%;
	}

	.col-5 {
		width: 41.66%;
	}

	.col-4 {
		width: 33.33%;
	}

	.col-3 {
		width: 25%;
	}

	.col-2 {
		width: 16.66%;
	}
	
	.col-1 {
		width: 8.33%;
	}

	@media (min-width: $breakpoint-xs) {
		.col-xs-12 {
			width: 100%;
		}

		.col-xs-11 {
			width: 91.66%;
		}

		.col-xs-10 {
			width: 83.33%;
		}

		.col-xs-9 {
			width: 75%;
		}

		.col-xs-8 {
			width: 66.66%;
		}

		.col-xs-7 {
			width: 58.33%;
		}

		.col-xs-6 {
			width: 50%;
		}

		.col-xs-5 {
			width: 41.66%;
		}

		.col-xs-4 {
			width: 33.33%;
		}

		.col-xs-3 {
			width: 25%;
		}

		.col-xs-2 {
			width: 16.66%;
		}
		
		.col-xs-1 {
			width: 8.33%;
		}
	}

	@media (min-width: $breakpoint-sm) {
		.col-sm-12 {
			width: 100%;
		}

		.col-sm-11 {
			width: 91.66%;
		}

		.col-sm-10 {
			width: 83.33%;
		}

		.col-sm-9 {
			width: 75%;
		}

		.col-sm-8 {
			width: 66.66%;
		}

		.col-sm-7 {
			width: 58.33%;
		}

		.col-sm-6 {
			width: 50%;
		}

		.col-sm-5 {
			width: 41.66%;
		}

		.col-sm-4 {
			width: 33.33%;
		}

		.col-sm-3 {
			width: 25%;
		}

		.col-sm-2 {
			width: 16.66%;
		}
		
		.col-sm-1 {
			width: 8.33%;
		}
	}

	@media (min-width: $breakpoint-md) {
		.col-md-12 {
			width: 100%;
		}

		.col-md-11 {
			width: 91.66%;
		}

		.col-md-10 {
			width: 83.33%;
		}

		.col-md-9 {
			width: 75%;
		}

		.col-md-8 {
			width: 66.66%;
		}

		.col-md-7 {
			width: 58.33%;
		}

		.col-md-6 {
			width: 50%;
		}

		.col-md-5 {
			width: 41.66%;
		}

		.col-md-4 {
			width: 33.33%;
		}

		.col-md-3 {
			width: 25%;
		}

		.col-md-2 {
			width: 16.66%;
		}
		
		.col-md-1 {
			width: 8.33%;
		}
	}

	@media (min-width: $breakpoint-lg) {
		.col-lg-12 {
			width: 100%;
		}

		.col-lg-11 {
			width: 91.66%;
		}

		.col-lg-10 {
			width: 83.33%;
		}

		.col-lg-9 {
			width: 75%;
		}

		.col-lg-8 {
			width: 66.66%;
		}

		.col-lg-7 {
			width: 58.33%;
		}

		.col-lg-6 {
			width: 50%;
		}

		.col-lg-5 {
			width: 41.66%;
		}

		.col-lg-4 {
			width: 33.33%;
		}

		.col-lg-3 {
			width: 25%;
		}

		.col-lg-2 {
			width: 16.66%;
		}
		
		.col-lg-1 {
			width: 8.33%;
		}
	}
	
	// ----------------------------------------------------

	.push-11 {
		margin-left: 91.66%;
	}

	.push-10 {
		margin-left: 83.33%;
	}

	.push-9 {
		margin-left: 75%;
	}

	.push-8 {
		margin-left: 66.66%;
	}

	.push-7 {
		margin-left: 58.33%;
	}

	.push-6 {
		margin-left: 50%;
	}

	.push-5 {
		margin-left: 41.66%;
	}

	.push-4 {
		margin-left: 33.33%;
	}

	.push-3 {
		margin-left: 25%;
	}

	.push-2 {
		margin-left: 16.66%;
	}

	.push-1 {
		margin-left: 8.33%;
	}

	@media (min-width: $breakpoint-xs) {
		.push-xs-11 {
			margin-left: 91.66%;
		}

		.push-xs-10 {
			margin-left: 83.33%;
		}

		.push-xs-9 {
			margin-left: 75%;
		}

		.push-xs-8 {
			margin-left: 66.66%;
		}

		.push-xs-7 {
			margin-left: 58.33%;
		}

		.push-xs-6 {
			margin-left: 50%;
		}

		.push-xs-5 {
			margin-left: 41.66%;
		}

		.push-xs-4 {
			margin-left: 33.33%;
		}

		.push-xs-3 {
			margin-left: 25%;
		}

		.push-xs-2 {
			margin-left: 16.66%;
		}

		.push-xs-1 {
			margin-left: 8.33%;
		}

		.push-xs-0 {
			margin-left: 0%;
		}
	}

	@media (min-width: $breakpoint-sm) {
		.push-sm-11 {
			margin-left: 91.66%;
		}

		.push-sm-10 {
			margin-left: 83.33%;
		}

		.push-sm-9 {
			margin-left: 75%;
		}

		.push-sm-8 {
			margin-left: 66.66%;
		}

		.push-sm-7 {
			margin-left: 58.33%;
		}

		.push-sm-6 {
			margin-left: 50%;
		}

		.push-sm-5 {
			margin-left: 41.66%;
		}

		.push-sm-4 {
			margin-left: 33.33%;
		}

		.push-sm-3 {
			margin-left: 25%;
		}

		.push-sm-2 {
			margin-left: 16.66%;
		}

		.push-sm-1 {
			margin-left: 8.33%;
		}

		.push-sm-0 {
			margin-left: 0%;
		}
	}

	@media (min-width: $breakpoint-md) {
		.push-md-11 {
			margin-left: 91.66%;
		}

		.push-md-10 {
			margin-left: 83.33%;
		}

		.push-md-9 {
			margin-left: 75%;
		}

		.push-md-8 {
			margin-left: 66.66%;
		}

		.push-md-7 {
			margin-left: 58.33%;
		}

		.push-md-6 {
			margin-left: 50%;
		}

		.push-md-5 {
			margin-left: 41.66%;
		}

		.push-md-4 {
			margin-left: 33.33%;
		}

		.push-md-3 {
			margin-left: 25%;
		}

		.push-md-2 {
			margin-left: 16.66%;
		}

		.push-md-1 {
			margin-left: 8.33%;
		}

		.push-md-0 {
			margin-left: 0%;
		}
	}

	@media (min-width: $breakpoint-lg) {
		.push-lg-11 {
			margin-left: 91.66%;
		}

		.push-lg-10 {
			margin-left: 83.33%;
		}

		.push-lg-9 {
			margin-left: 75%;
		}

		.push-lg-8 {
			margin-left: 66.66%;
		}

		.push-lg-7 {
			margin-left: 58.33%;
		}

		.push-lg-6 {
			margin-left: 50%;
		}

		.push-lg-5 {
			margin-left: 41.66%;
		}

		.push-lg-4 {
			margin-left: 33.33%;
		}

		.push-lg-3 {
			margin-left: 25%;
		}

		.push-lg-2 {
			margin-left: 16.66%;
		}

		.push-lg-1 {
			margin-left: 8.33%;
		}

		.push-lg-0 {
			margin-left: 0%;
		}
	}
}